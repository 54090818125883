<template>
  <div class="affiliate-hero">
    <div class="container">
      <div class="row">
        <div class="message_container col-sm-12 col-md-6">
          <h1 class="hero-title">
            Make Money Online <br />
            with <span class="green-text"> Affiliate</span><br />
            Marketing.
          </h1>

          <p class="hero-paragraph hidden-on-mobile">
            Earn commission for every dropshipper referral! Join our affiliate
            program now and start earning passive income, whether you're a
            Shopify app developer, blogger, video creator, influencer, Shopify
            expert, agency, or store builder.
          </p>
          <p class="hero-paragraph hidden-on-web">
            VGANG connects vegan, sustainable suppliers and brands to online
            dropshippers and increases their distribution around Europe.
          </p>
          <a href="https://calendly.com/vgang/vgang-for-influencers">
            <button
              class="d-flex align-items-center justify-content-center custom-button filled hero-button"
            >
              <img
                src="@/assets/icons/calendar_event.svg"
                style="margin-right: 5px"
                width="20px"
                height="20px"
                alt="shopify logo"
              />
              Schedule a call with us
            </button>
          </a>
        </div>
        <div class="img_container col-sm-12 col-md-6 hidden-on-mobile">
          <div class="image-container">
            <img
              loading="lazy"
              src="@/assets/img/affiliate/hero-8-bg-shape-cover-dark.webp"
              alt="a happy women works with phone"
              class="shopify-hero-image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="partnership shopify-landing">
    <Hero />
    <JoinAffiliate
      url="https://calendly.com/vgang/vgang-for-influencers"
      class="hidden-on-mobile"
    />
    <BenefitsOfAffiliate />
    <JoinAffiliate class="hidden-on-web" />
    <QaBaseComponent
      titleFor="Influencers"
      api="https://blog.vgang.io/wp-json/wp/v2/posts?categories=33&per_page=6&offset=0"
    />

    <JoinTheMovement
      url="https://calendly.com/vgang/vgang-for-influencers"
      :isScheduleCall="true"
    />
  </div>
</template>

<script>
import Hero from "@/components/affiliates/Hero.vue";
import BenefitsOfAffiliate from "../components/affiliates/BenefitsOfAffiliate.vue";
import QaBaseComponent from "@/components/bases/QaBaseComponent.vue";
import JoinTheMovement from "../components/bases/JoinTheMovement.vue";
import JoinAffiliate from "@/components/affiliates/JoinAffiliate.vue";

export default {
  name: "Affiliates",
  components: {
    BenefitsOfAffiliate,
    Hero,
    QaBaseComponent,
    JoinTheMovement,
    JoinAffiliate,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss"></style>

<template>
  <section class="benefit-of-affiliate-section">
    <div class="container">
      <div class="getting-started">
        <div class="row">
          <div class="col-sm-12">
            <h1 class="section-title">Benefits of VGANG affiliate program</h1>
            <p class="section-paragraph">
              Join VGANG Affiliate Program and Help Spread the Mission of
              Sustainability While Earning Commission.
            </p>
          </div>
        </div>
        <div class="cards-container">
          <div class="row">
            <div class="col-xl-6 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/affiliate/money.webp"
                alt=" High Commissions for Life"
                title="High Commissions for Life"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
                  <span class="text-green"> High</span> Commissions for Life
                </h3>
                <p class="description mt-3">
                  Earn up to 50% commission on every referral you make, with
                  commissions that last for the lifetime of the subscription.
                </p>
              </div>
            </div>
            <div class="col-xl-6 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/affiliate/heaurt.webp"
                alt=" Comprehensive Support"
                title="Comprehensive Support"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
                  <span class="text-green"> Comprehensive</span> Support
                </h3>
                <p class="description mt-3">
                  Access to all the tools you need to promote VGANG, including
                  exclusive discounts, creative assets, Content, and
                  personalized support.
                </p>
              </div>
            </div>
            <div class="col-xl-6 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/affiliate/payouts.webp"
                alt="Easy Monthly Payouts"
                title="Easy Monthly Payouts"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
                  Easy Monthly <span class="text-green"> Payouts</span>
                </h3>
                <p class="description mt-3">
                  Track how much money you make, and easily cash out your
                  earnings each month through PayPal or Stripe.
                </p>
              </div>
            </div>
            <div class="col-xl-6 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/affiliate/planet.webp"
                alt="Join the Fight for the Planet"
                title="Join the Fight for the Planet"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
                  Join the Fight for the
                  <span class="text-green"> Planet</span>
                </h3>
                <p class="description mt-3">
                  Join the mission to fight for the planet by promoting vegan
                  and planet-friendly products to your audience.
                </p>
              </div>
            </div>
          </div>
          <div class="buttons-container mt-10 hidden-on-mobile">
            <a
              target="_blank"
              class="d-flex justify-content-center align-items-center gap-2 custom-button filled"
              href="https://apps.shopify.com/vgang-dropshipping-retailers?surface_detail=vgang+for+dropshipper&surface_inter_position=1&surface_intra_position=1&surface_type=search"
            >
              <img
                src="@/assets/icons/Shopify_logo_w.svg"
                alt="Shopify"
                title="Shopify"
                loading="lazy"
              />
              Get Shopify App for Free
            </a>
            <a
              style="border-color: #d0d5dd"
              target="_blank"
              class="d-flex justify-content-center mt-5 align-items-center gap-2 custom-button"
              href="https://apps.shopify.com/vgang-dropshipping-retailers?surface_detail=vgang+for+dropshipper&surface_inter_position=1&surface_intra_position=1&surface_type=search"
            >
              Book a Demo
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "GettingStarted",
  props: {
    msg: String,
  },
};
</script>
<style lang="scss" scoped>
.card {
  background: unset;
}
</style>
